const getSites = async () => {
    let result = null
    try {
        result = await window.axios.get('/v1/sites/portfolio')
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getEndUsesForSite = async (siteId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/enduse/site/${siteId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getMonitorsForSite = async (siteId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/monitor/site/${siteId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};


const getEquipmentForSite = async (siteId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/equipment/site/${siteId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};


const getRegistersForMonitorAndResourceType = async (monitorId, resourceTypeId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/register/monitor/${monitorId}/resourcetype/${resourceTypeId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getResourceTypes = async () => {
    let result = null
    try {
        result = await window.axios.get(`/v1/resourcetype/index`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

// const getEndUseUnitOfMeasuresForResourceType = async (resourceTypeId) => {
//     let result = null
//     try {
//         result = await window.axios.get(`/v1/enduseunitofmeasure/${resourceTypeId}`)
//         let list = result.data.data;
//         return list;
//     } catch (err) {
//         console.error(err);
//         return Promise.reject(err)
//     }
// };

const getEndUseUnitOfMeasuresForRegister = async (registerId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/enduseunitofmeasure/${registerId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getEndUseParentsByEndUseUnitOfMeasureId = async (siteId, unitOfMeasureId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/enduse/site/${siteId}/enduseunitofmeasure/${unitOfMeasureId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};


const getPhases = () => {
    return [
        { phaseId: 1, name: 'SinglePhase' },
        { phaseId: 2, name: 'ThreePhase' },
    ]
};

const getEndUseTypes = async (resourceTypeId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/endusetype/resourcetype/${resourceTypeId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getEndUseTypesByResourceTypeAndEquipmentType = async (resourceTypeId, equipmentId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/endusetype/resourcetype/${resourceTypeId}/equipmentid/${equipmentId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};


const createEndUse = async (endUse) => {
    let result = null
    console.log(endUse);
    //debugger;
    try {
        result = await window.axios.post('/v1/enduse/create', endUse)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const updateEndUse = async (endUse) => {
    let result = null
    console.log(endUse);
    //debugger;
    try {
        result = await window.axios.put('/v1/enduse/update', endUse)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};


const deleteEndUse = async (endUse) => {
    let result = null
    console.log(endUse);

    let config = {
        data: endUse
    }
    //debugger;
    try {
        result = await window.axios.delete('/v1/enduse/delete', config)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getEndUse = async (siteId, monitorId, registerId, endUseId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/enduse/update/?siteId=${siteId}&monitorId=${monitorId}&registerId=${registerId}&endUseId=${endUseId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};


export default {
    getSites,
    getEndUsesForSite,
    getMonitorsForSite,
    getRegistersForMonitorAndResourceType,
    getResourceTypes,
    // getEndUseUnitOfMeasuresForResourceType,
    getEndUseUnitOfMeasuresForRegister,
    getEndUseParentsByEndUseUnitOfMeasureId,
    getEquipmentForSite,
    getPhases,
    getEndUseTypes,
    getEndUseTypesByResourceTypeAndEquipmentType,
    getEndUse,
    createEndUse,
    deleteEndUse,
    updateEndUse,
}