<template>
  <v-container grid-list-xl>
    <p class="headline pt-3">Create End Use</p>
    <validation-observer ref="obs" v-slot="{ invalid }">
      <v-form @submit.prevent="submit" v-model="valid">
        <v-sheet elevation="2" class="pa-4">
          <v-row>
            <v-col sm="6">
              <validated-text-field
                v-model="endUseName"
                label="End Use Name"
                hint="Name for this End Use"
                persistent-hint
                rules="required"
              ></validated-text-field
            ></v-col>
            <v-col sm="6">
              <validated-select-field
                :items="resourceTypes"
                item-value="id"
                item-text="resourceTypeName"
                rules="required"
                v-model="selectedResourceType"
                label="Resource Type"
                hint="What type of endUse is this"
                persistent-hint
                v-on:input="handleSetResourceType($event)"
              ></validated-select-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <validated-select-field
                :items="monitors"
                item-value="id"
                item-text="deviceName"
                rules="required"
                v-model="selectedMonitor"
                label="Monitor"
                hint="What monitor is this End Use assigned to"
                persistent-hint
                v-on:input="handleSetMonitor($event)"
                :disabled="selectedResourceType === null"
              ></validated-select-field>
            </v-col>
            <v-col sm="6">
              <validated-select-field
                :items="registers"
                item-value="registerId"
                item-text="registerName"
                rules="required"
                v-model="selectedRegister"
                label="Register"
                hint="Assign Register to this End Use"
                v-on:input="handleSetRegister($event)"
                persistent-hint
              ></validated-select-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <validated-select-field
                :items="endUseUnitOfMeasures"
                item-value="id"
                item-text="unitOfMeasureName"
                rules="required"
                v-model="selectedUnitOfMeasure"
                label="End Use Unit of Measure"
                hint="What what unit of measure does this endUse use"
                v-on:input="handleSetEndUseUnitOfMeasure($event)"
                persistent-hint
              ></validated-select-field>
            </v-col>

            <v-col sm="6">
              <v-autocomplete
                :items="parents"
                item-value="parentId"
                item-text="parentName"
                clearable
                v-model="selectedParent"
                label="End Use Parent"
                hint="Parent for this End Use"
                persistent-hint
                outlined
              ></v-autocomplete
            ></v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <v-autocomplete
                :items="equipment"
                item-value="equipmentId"
                item-text="equipmentName"
                clearable
                v-model="selectedEquipment"
                label="Equipment"
                hint="Assign Equipment to this End Use"
                persistent-hint
                v-on:input="handleSetEquipment($event)"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col sm="6">
              <v-autocomplete
                :items="phases"
                item-value="phaseId"
                item-text="name"
                clearable
                v-model="selectedPhase"
                label="Phase"
                hint="Assign Phase to this End Use"
                persistent-hint
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-spacer />
            <v-col sm="6">
              <v-row>
                <v-col sm="7">
                  <validated-select-field
                    :items="endUseTypesWithPercentages"
                    item-value="endUseTypeId"
                    item-text="endUseTypeWithPercentage"
                    rules=""
                    v-model="selectedEndUseType"
                    label="End UseType"
                    hint="What end use type does this endUse measure"
                    persistent-hint
                    v-on:input="handleSetEndUseType($event)"
                  ></validated-select-field>
                </v-col>
                <v-col sm="5">
                  <v-text-field
                    v-model="selectedEndUseTypePercentage.percentage"
                    label="Percentage"
                    hint="Percent of end usage"
                    persistent-hint
                    type="number"
                    min="0"
                    max="100"
                    step="10"
                    ref="percentText"
                    v-on:input="handleChangePercentValue"
                    outlined
                  >
                    <template v-slot:append-outer>
                      <v-btn
                        color="primary"
                        title="Set end use percentage"
                        @click="handleSetEndUseTypePercentage"
                      >
                        Set
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn type="submit" color="primary" :disabled="!isValidEndUse">Save</v-btn>
              <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-sheet>

        <v-row justify="center">
          <v-dialog v-model="dialog" max-width="600">
            <v-card>
              <v-card-title class="headline"
                >Validating Utility End Use</v-card-title
              >

              <v-card-text>
                <v-stepper v-model="vSteps" vertical>
                  <v-stepper-step
                    :complete="vSteps > 1 && isValidEndUseName"
                    step="1"
                    color="green"
                  >
                    Validating End Use Name
                    <small v-if="vSteps > 1 && isValidEndUseName === true"
                      >endUse Name is valid</small
                    >
                    <v-row
                      style="width: 500px"
                      v-if="vSteps > 1 && isValidEndUseName === false"
                    >
                      <v-col class="pt-0" cols="2" md="9" sm="9">
                        <small v-if="vSteps > 2 && isValidEndUseName === false"
                          >You have a duplicate endUse name</small
                        >
                      </v-col>
                      <v-col cols="auto" md="3" sm="3">
                        <v-btn
                          class="mt-n7"
                          color="primary"
                          title="assign a Role"
                          @click="fixendUseErrors(0)"
                          >Fix</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-stepper-step>

                  <v-stepper-content step="1">
                    <v-progress-linear
                      indeterminate
                      color="green"
                    ></v-progress-linear>
                  </v-stepper-content>
                  <v-stepper-step
                    :complete="vSteps > 2 && isValidFriendlyName === true"
                    step="2"
                    color="green"
                  >
                    Validating Display Name
                    <small v-if="vSteps > 2 && isValidFriendlyName === true">{{
                      "Display Name is valid"
                    }}</small>
                    <v-row
                      style="width: 500px"
                      v-if="vSteps > 2 && isValidFriendlyName === false"
                    >
                      <v-col class="pt-0" cols="2" md="9" sm="9">
                        <small v-if="vSteps > 2">{{
                          "enter a unique display name"
                        }}</small>
                      </v-col>
                      <v-col cols="auto" md="3" sm="3">
                        <v-btn
                          class="mt-n7"
                          color="primary"
                          title="enter a unique display name"
                          @click="fixendUseErrors(1)"
                          >Fix</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-stepper-step>
                  <v-stepper-content step="2">
                    <v-progress-linear
                      indeterminate
                      color="green"
                    ></v-progress-linear>
                  </v-stepper-content>

                  <v-stepper-step
                    :complete="vSteps > 3 && isValidPercentage === true"
                    step="3"
                    color="green"
                  >
                    Validating End Use Percentages
                    <small v-if="vSteps > 3 && isValidPercentage === true">{{
                      "End use percentages are valid"
                    }}</small>
                    <v-row
                      style="width: 500px"
                      v-if="vSteps > 3 && isValidPercentage === false"
                    >
                      <v-col class="pt-0" cols="2" md="9" sm="9">
                        <small v-if="vSteps > 3">{{
                          `end use percentages do not equal 100% (currently ${percentageSum}%)`
                        }}</small>
                      </v-col>
                      <v-col cols="auto" md="3" sm="3">
                        <v-btn
                          class="mt-n7"
                          color="primary"
                          title="adjust end use percentages"
                          @click="fixendUseErrors(2)"
                          >Fix</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-stepper-step>
                  <v-stepper-content step="3">
                    <v-progress-linear
                      indeterminate
                      color="green"
                    ></v-progress-linear>
                  </v-stepper-content>
                </v-stepper>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="green darken-1" text @click="cancelDialog"
                  >Cancel</v-btn
                >
                <v-btn
                  color="green darken-1"
                  :disabled="!isValidEndUse || invalid"
                  text
                  @click="dismissDialog"
                  >Continue</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-form></validation-observer
    ></v-container
  >
</template>

<script>
import goTo from "vuetify/es5/services/goto";
import { mixin as VueTimers } from "vue-timers";
import { ValidationObserver } from "vee-validate";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import ValidatedSelectField from "@/components/Fields/ValidatedSelectField";
import api from "../_api";

export default {
  components: {
    "validated-text-field": ValidatedTextField,
    "validated-select-field": ValidatedSelectField,
    "validation-observer": ValidationObserver,
  },

  timers: {
    metaValidator: { time: 1000, autostart: false, repeat: true },
  },

  mixins: [VueTimers],

  data() {
    return {
      valid: true,
      endUse: {
        siteId: null,
        monitorId: null,
        registerId: null,
        parentId: null,
        endUseUnitOfMeasureId: null,
        endUseName: null,
        equipmentId: null,
        resourceTypeId: null,
        phaseId: null,
        endUseTypePercentageResourceModels: [],
      },
      //deviceName: null,
      endUseName: null,
      isValidating: false,
      vSteps: 0,
      isValidEndUse: true,
      isValidEndUseName: false,
      isValidFriendlyName: false,
      isValidLocation: false,
      dialog: false,
      siteId: null,

      selectedResourceType: null,
      selectedUnitOfMeasure: null,
      selectedRegister: null,
      selectedParent: null,
      selectedEquipment: null,
      selectedPhase: null,
      selectedMonitor: null,

      selectedEndUseType: null,
      selectedEndUseTypePercentage: {
        endUseTypeId: null,
        percentage: 0,
      },
      percentageSum: 100,
      createEndUseTypePercentageResourceModels: [],
      resourceTypes: [],
      endUseUnitOfMeasures: [],
      endUseTypes: [],
      endUseTypesWithPercentages: [],
      monitors: [],
      phases: [],
      registers: [],
      equipment: [],
      parents: [],
    };
  },

  async created() {
    this.siteId = this.$route.params.siteId;
    try {
      await this.getLookups(this.siteId);
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    async getLookups() {
      this.monitors = await api.getMonitorsForSite(this.siteId);
      this.resourceTypes = await api.getResourceTypes();
      this.equipment = await api.getEquipmentForSite(this.siteId);
      this.phases = api.getPhases();
    },

    async clearForResourceType() {
      this.createEndUseTypePercentageResourceModels = [];
      this.endUseUnitOfMeasures = [];
      this.endUseTypes = [];
      this.endUseTypesWithPercentages = [];
      //this.equipment = [];
      this.parents = [];
      this.registers = [];
      this.selectedUnitOfMeasure = null;
      this.selectedRegister = null;
      this.selectedParent = null;
      this.selectedEquipment = null;
      this.selectedPhase = null;
      this.selectedMonitor = null;
      this.selectedEndUseTypePercentage.percentage = 0;
      this.$refs.obs.reset();
    },

    async clearForMonitor() {
      this.createEndUseTypePercentageResourceModels = [];
      this.endUseUnitOfMeasures = [];
      this.endUseTypes = [];
      this.endUseTypesWithPercentages = [];
      //this.equipment = [];
      this.parents = [];
      this.registers = [];
      this.selectedUnitOfMeasure = null;
      this.selectedRegister = null;
      this.selectedParent = null;
      this.selectedEquipment = null;
      this.selectedPhase = null;
      this.selectedEndUseTypePercentage.percentage = 0;
      this.$refs.obs.reset();
    },

    async submit() {
      let valid = await this.$refs.obs.validate();
      if (valid === false) {
        return;
      }

      this.isValidating = false;
      this.validateEndUse();
    },

    async handleSetResourceType(resourceTypeId) {
      console.log(resourceTypeId);
      this.clearForResourceType();
    },

    handleSetEndUseType(endUseTypeId) {
      this.selectedEndUseTypePercentage = this.endUseTypesWithPercentages.find(
        (p) => p.endUseTypeId === endUseTypeId
      );
    },

    async handleSetRegister(registerId) {
      this.endUseUnitOfMeasures = await api.getEndUseUnitOfMeasuresForRegister(
        registerId
      );
    },

    async handleSetEquipment(equipmentId) {
      //further filter endUseTypes
      this.endUseTypes = await api.getEndUseTypesByResourceTypeAndEquipmentType(
        this.selectedResourceType,
        equipmentId
      );
      this.endUseTypesWithPercentages = this.mapEndUseTypesPercentages(
        this.endUseTypes
      );
      this.selectedEndUseTypePercentage.percentage = 0;
    },

    async handleSetEndUseUnitOfMeasure(endUseUnitOfMeasureId) {
      this.parents = await api.getEndUseParentsByEndUseUnitOfMeasureId(
        this.siteId,
        endUseUnitOfMeasureId
      );

      this.endUseTypes = await api.getEndUseTypes(this.selectedResourceType);
      this.endUseTypesWithPercentages = this.mapEndUseTypesPercentages(
        this.endUseTypes
      );
      this.selectedEndUseTypePercentage.percentage = 0;
    },

    async handleSetMonitor(selectedMonitor) {
      this.clearForMonitor();
      this.registers = await api.getRegistersForMonitorAndResourceType(
        selectedMonitor,
        this.selectedResourceType
      );
    },

    handleChangePercentValue() {
      this.isValidEndUse = false;
    },

    handleSetEndUseTypePercentage() {
      const self = this;

      const updatedPercentages = this.endUseTypesWithPercentages.map((p) =>
        p.endUseTypeId === self.selectedEndUseTypePercentage.endUseTypeId
          ? {
              ...p,
              percentage: self.selectedEndUseTypePercentage.percentage * 1,
              endUseTypeWithPercentage: `${p.endUseType} - (${self.selectedEndUseTypePercentage.percentage}%)`,
            }
          : p
      );
      this.endUseTypesWithPercentages = [...updatedPercentages];
      this.isValidEndUse = true;
    },

    mapEndUseTypesPercentages(endUseTypes) {
      const percentage = 0;
      let mapped = endUseTypes.map((endUseType) => {
        return {
          endUseTypeId: endUseType.id,
          percentage: percentage,
          endUseType: endUseType.endUseType,
          endUseTypeWithPercentage: `${endUseType.endUseType} - (${percentage}%)`,
        };
      });
      return mapped;
    },

    mapCreateEndUseTypePercentageResourceModels() {
      const mapped = this.endUseTypesWithPercentages.map((eut) => {
        return {
          endUseTypeId: eut.endUseTypeId,
          percentage: eut.percentage / 100,
        };
      });
      return mapped;
    },

    validateEndUse() {
      this.isValidating = true;
      this.isValidEndUseName = false;
      this.isValidFriendlyName = false;
      this.isValidPercentage = false;
      this.dialog = true;
      this.$timer.start("metaValidator");
    },

    fixendUseErrors(item) {
      this.cancelDialog();
      //this.isValidEndUse = true;
      console.log(`fixing validation item ${item}`);
      switch (item) {
        case 2:
          this.$nextTick().then(() => {
            this.focusPercentage();
          });

          break;

        default:
          break;
      }
    },

    focusPercentage() {
      console.log("focus");
      console.log(this.$refs.percentText.$el);
      this.$refs.percentText.focus();
    },

    cancelDialog() {
      this.isValidating = false;
      this.dialog = false;
      this.$timer.stop("metaValidator");
      this.vSteps = 0;
    },

    async dismissDialog() {
      this.cancelDialog();
      if (this.isValidEndUse) {
        goTo(0); //scroll up
        this.loading = true;
        try {
          this.endUse.siteId = this.siteId;
          this.endUse.monitorId = this.selectedMonitor;
          this.endUse.registerId = this.selectedRegister;
          this.endUse.parentId = this.selectedParent;
          this.endUse.endUseUnitOfMeasureId = this.selectedUnitOfMeasure;
          this.endUse.endUseName = this.endUseName;
          this.endUse.equipmentId = this.selectedEquipment;
          this.endUse.resourceTypeId = this.selectedResourceType;
          this.endUse.phaseId = this.selectedPhase;

          this.endUse.EndUseTypePercentageResourceModels = this.mapCreateEndUseTypePercentageResourceModels();

          await api.createEndUse(this.endUse);
          //console.log(this.endUse);

          this.$router.push({ name: "EndUseIndex" });
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      }
    },

    metaValidator() {
      this.vSteps++;

      if (this.vSteps == 1) {
        // compare proposed name to cached list of endUses for site
        this.isValidEndUseName = true;
      }
      if (this.vSteps == 2) {
        // resource type must be selected
        this.isValidFriendlyName = true;
      }

      if (this.vSteps == 3) {
        // resource type must be selected
        this.isValidPercentage = this.validateEndUsePercentages();
      }

      if (this.vSteps > 3) {
        this.$timer.stop("metaValidator");
        this.isValidating = false;
      }

      this.isValidEndUse =
        this.isValidEndUseName &&
        this.isValidFriendlyName &&
        this.isValidPercentage;

      console.log(`validendUse: ${this.isValidEndUse}`);
    },

    validateEndUsePercentages() {
      let sumOfPercentages = 0;
      this.endUseTypesWithPercentages.forEach((e) => {
        sumOfPercentages += e.percentage;
      });

      //this.endUseTypesWithPercentages.map(item => item.percentage).reduce((prev, next) => prev + next);
      console.log(sumOfPercentages);
      this.percentageSum = sumOfPercentages;
      return sumOfPercentages === 100;
    },

    handleCancelCrud() {
      this.$router.push({name: "EndUseIndex"});
    }
  },
};
</script>